import Vue from 'vue';
import {get, post} from '@/services/api';
import {companyIndex, InvoiceInformationIndex, userCreateCompany, validateEmail} from '../../services/endpoint.json';
import {mapMutations} from "vuex";
import show_alert_mixin from "@/utils/show_alert_mixin";

const fieldCompany = {
  userId: '',
  emailCompany: '',
  nameCompany: '',
  numberCompany: '',
  legalRepresentative: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  continent: '',
  phone: '',
  website: '',
  socialMedia: {
    facebook: '',
    instagram: '',
    twitter: '',
  },
  typeCompany: '',
  zipCode: '',
  otherDsg: '',
  brandOtherDsg: '',
  distributorVag: '',
  elevator: '',
  dinanometer: 0,
  repairDsg: 0,
  sellingHardwareDsg: 0,
  aboutTvs: '',
  promotionalActivities: '',
  futureTvs: '',
  registerSubdealer: '',
};
export default Vue.extend({
  name: 'ValidateEmail',
  components: {},
  mixins: [show_alert_mixin],
  data: () => ({
    showError: false,
    origin: '',
    company: fieldCompany,
    invoiceConfiguration: {
      accountHolder: null,
      account: null,
      swiftCode: null,
      address: null
    },
    userId: null,
    showButtonUpdateCompany: true,
    showButtonUpdateInvoice: true,
    userType: null,
    showFormInvoice: false,
    formErrorInvoice: {
      holder: false,
      account: false,
      code: false,
      address: false,
    },
    isConfirm: 'false',
    disabledButton: false
  }),
  beforeCreate() {
    this.$store.commit('loaderManager', true);
  },
  mounted() {
    this.origin = window.location.origin
    this.userId = this.$route.query.id
    this.userType = this.$route.query.type
    this.isConfirm = (this.$route.query.confirm !== undefined) ? this.$route.query.confirm : 'false';
    this.validateInfo().then();
    if (this.userType === '["subdealer"]' || this.userType === 'subdealer') {
      this.showFormInvoice = false
    }
    this.getData().then()
  },
  methods: {
    ...mapMutations(['loaderManager', 'notificationManager']),
    async validateInfo() {
      const params = this.$route.query;
      const {data} = await get(validateEmail, `${params.id}/${params.hash}`, false);
      if (Number(data.statusCode) === Number(200)) {
        this.showError = false
        setTimeout(() => {
          this.$store.commit('loaderManager', false);
        }, 1000)
      } else {
        this.$store.commit('loaderManager', false);
        this.showError = true;
      }
    },
    async getData() {
      const {data} = await get(`${companyIndex}/${this.userId}`, null, true).then()
      const tempInvoice = await post(InvoiceInformationIndex, {id: this.userId}, true)
      if (tempInvoice.data.message.configuration !== null && tempInvoice.data.message.configuration !== undefined) {
        this.invoiceConfiguration = tempInvoice.data.message.configuration.extraData
      }
      if (data.message.company !== null) {
        this.company = data.message.company
      }
    },
    async updateCompany() {
      this.disabledButton = true
      this.company.userId = this.userId
      this.company.id = this.userId
      this.company.other = true
      this.company.originEmailVerification = true
      await post(userCreateCompany, this.company, true).then()
      await this.getData()
      this.showAlertNotification("Information updated")
      setTimeout(() => {
        this.$router.push({name: 'login'}).then().catch();
      }, 2000)
    },
  },
});
